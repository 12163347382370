import { lazy, Suspense, useEffect, useRef, useState } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import "./Services/Index.css";
import Login from "./Pages/Auth/Login";
import { HubConnectionBuilder } from "@microsoft/signalr";
import ForgotPassword from "./Pages/Auth/ForgotPassword";
import ChangePassword from "./Pages/Auth/ChangePassword";
import { useDispatch, useSelector } from "react-redux";
import { getList } from "./Config/SharedFunctions";
import { chatUrl, urls } from "./Config/Consts";
import { updateMessages } from "./Store/Action";
import { getUserfromSS, returningValue } from "./Config/SmallFunctions";
import moment from "moment";
import { Context } from "./Context/ContextProvider";
import UnAuthorisedAccess from "./PrivateRoutes/UnAuthorisedAccess";
import PolicyAcceptency from "./Pages/CompanyPolicies/PolicyAcceptency";
import TermsAndConditions from "./Pages/CompanyPolicies/TermsAndConditions";

const RC = lazy(() => import("./Pages/RouteConfig"));

const App = () => {
  // const { language } = Context();
  const [table, setTable] = useState(false);
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.messages);
  const loggedInUserId = useSelector((state) => state.loggedInUserId);
  const allMessages = useRef(null);
  allMessages.current = messages;

  const getChat = async () => {
    let res = await getList(urls.chat.getAll, { id: getUserfromSS()?.empId });
    res = res?.map((e) => ({ ...e, createdDate: returningValue(e?.createdDate, "LocalTime") }));
    dispatch(updateMessages(res));
  };

  useEffect(() => {
    if (loggedInUserId) {
      let connection = new HubConnectionBuilder().withUrl(chatUrl).build();


      connection.on("ReceiveChat", (updatedMessage) => {
        let res = [...updatedMessage];
        let userId = getUserfromSS()?.empId;
        res = res?.filter((v) => [v?.senderId, v?.receiverId]?.some((a) => a == userId));
        res = res?.map((e) => ({ ...e, createdDate: moment.utc(e?.createdDate, "YYYY-MM-DDTHH:mm:ss").local().format("YYYY-MMM-DD H:mm:ss") }));
        dispatch(updateMessages(res));
      });
      connection
        .start()
        .then(() => console.log("Connection established."))
        .catch((err) => console.error("Error establishing connection:", err));
    }
  }, [loggedInUserId]);

  useEffect(() => {
    loggedInUserId > 0 && getChat();
  }, [loggedInUserId]);

  const getAccessLinks = async () => {
    let res1 = await getList(urls.userAccess.getUserAccessLinks, { subscriptionId: getUserfromSS()?.roleId });
    let links = res1?.filter((v) => v?.status == "Active")?.map((e) => e.linkName);
    sessionStorage.setItem("accessLinks", JSON.stringify(links));
  };

  useEffect(() => {
    getUserfromSS()?.roleId > 0 && getAccessLinks();
  }, []);

  // useEffect(() => {
  //   if (process.env.REACT_APP_ENV === 'PROD') {
  //     const handleContextMenu = (event) => {
  //       event.preventDefault();  
  //     };

  //     const handleKeyDown = (event) => {
  //        if (event.key === 'F12') {
  //         event.preventDefault();
  //       }
        
  //        if (event.ctrlKey && event.shiftKey && event.key === 'I') {
  //         event.preventDefault();
  //       }
  //     };

  //     document.addEventListener('contextmenu', handleContextMenu); 
  //     document.addEventListener('keydown', handleKeyDown);  

  //     return () => {
  //       document.removeEventListener('contextmenu', handleContextMenu);
  //       document.removeEventListener('keydown', handleKeyDown);
  //     };
  //   }
  // }, []);
  return (
    <>
      {/* <div className="loading-indicator">

    </div> */}
      <Suspense fallback={() => <div>Loading ....</div>}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="privacy" element={<TermsAndConditions/>}/>
            <Route path="policyacceptency/:id" element={<PolicyAcceptency />} />
            <Route path="login" element={<Login />} />
            <Route path="sessionexpired" element={<UnAuthorisedAccess />} />
            <Route path="changepassword" element={<ChangePassword />} />
            <Route path="/createpassword/:token" element={<ForgotPassword />} />
            <Route path="v1/*" element={<RC />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </>
  );
};

export default App
