 
import Loginz1 from "../../Assets/logingz_white.png";

import { InputGroup, Form, Col, Card, Button } from "react-bootstrap";
import { GoEye, GoEyeClosed } from "react-icons/go";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UseFormValidations } from "../../FormValidation/UseFormValidations";
import "./Auth.css";
import { forgetPassword, login } from "../../Config/ValidationSchema";
import { getList, notify, save } from "../../Config/SharedFunctions";
import { urls } from "../../Config/Consts"; 
import { IoIosArrowRoundForward } from "react-icons/io";
 
function ForgotPassword() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [type1, setType1] = useState("password");
  const [type2, setType2] = useState("password");

  const isPasswordComplex = (password) => {
     const capitalLetterRegex = /[A-Z]/;
    const smallLetterRegex = /[a-z]/;
    const specialCharacterRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
    const numberRegex = /[0-9]/;
     return (
      password.length >= 6 &&
      capitalLetterRegex.test(password) &&
      smallLetterRegex.test(password) &&
      specialCharacterRegex.test(password) &&
      numberRegex.test(password)
    );
  };
  

  const submit = async () => {
    if (data?.password === data?.confirmPassword) {
       if (!isPasswordComplex(data?.password)) {
        notify(false, "Password must contain at least one capital letter, one small letter, one special character, one number, and have a length greater than 6 characters.");
        return;
      }
      let body = { token: token, password: data?.password };
      let res = await save(urls.Auth.CreatePassword, body);
      if (res?.data?.status === true) {
        navigate("/");
      }
    } else {
      notify(false, "Password and confirm password should be the same");
    }
  };

  const { data, errors, handleSubmit, formChange } = UseFormValidations({
    validationSchema: forgetPassword,
    submit: submit,
  });

  return (
    <section className="vh-100 background-image">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-between">
          <Col lg={8} xs={12} md={6}>
            <img src={Loginz1} height={100} />
          </Col>
          <Col lg={4} xs={12} md={6} className="background-shade">
            <Card className="transparent-card  px-lg-5 py-lg-3 my-lg-5 my-md-2">
              <Card.Body className="transparent-card ">
                <div className="text-start mb-xl-5 mb-lg-4 mt-lg-5">
                  <h1 className="text-white"> Welcome Back </h1>
                  <h5 className="text-white">Existing Member Login</h5>
                </div>

                <Form id="login-form" onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label className="text-white">Password </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control size="lg" type={type1} required name="password" className="input-heigt rounded-0" placeholder="Password" onChange={formChange("")} />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label className="text-white">Confirm Password</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control size="lg" type={type2} name="confirmPassword" required placeholder="Confirm Password" className={`input-heigt password rounded-0 `} onChange={formChange("")} />
                      <InputGroup.Text className="rounded-0">
                        <span onClick={() => setType2(type2 === "password" ? "text" : "password")} id="togglePassword">
                          {type2 === "password" ? <GoEyeClosed /> : <GoEye />}
                        </span>
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>

                  <div className="text-center ">
                    <Button size="lg" className="btn-size mt-5 w-100 d-flex justify-content-between align-items-center rounded-0" variant="light" type="submit">
                      <span>Save</span> <IoIosArrowRoundForward size={25} />
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </div>
      </div>
    </section>
  );
}

export default ForgotPassword;
