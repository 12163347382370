export const updateMessages = (payload) => ({
    type:"UPDATE_MESSAGES",
    payload,
})

export const updateLoggedInUser = (payload) => ({
    type:"UPDATE_LOGGEDINUSER",
    payload,
})

export const updateNotificationInfo = (payload) => ({
    type:"UPDATE_NOTIFICATION_INFO",
    payload,
})

export const celebrationsList=(payload)=>({
    type:"CELEBRATIONS",
    payload,
})
export const changeTimeSheetDataState=(payload)=>({
    type:"CHANGETIMESHEETDATA",
    payload,
})