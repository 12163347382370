import React, { createContext, useState, useEffect, useContext } from 'react';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(() => {
      const storedTheme = sessionStorage.getItem('theme');
      return storedTheme ? storedTheme : 'light';
    });
 
  const [theme2, setTheme2] = useState(() => {
    const storedTheme = sessionStorage.getItem('theme2');
    return storedTheme ? storedTheme : 'light';
  });

  useEffect(() => {
    document.body.className = `theme-${theme}`;
    sessionStorage.setItem('theme', theme);
  }, [theme]);

 


  const toggleTheme = () => {
    setTheme(prevTheme => (prevTheme === 'light' ? 'dark' : 'light'));
    setTheme2(prevTheme => (prevTheme === 'light' ? 'dark' : 'light'));
  };
 

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme}}>
      {children}
    </ThemeContext.Provider>
  );
};
