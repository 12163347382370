import React from 'react';
import { useNavigate } from 'react-router-dom';

const Privacy = () => {
  const navigate=useNavigate()
  return (
    <div>
      <div className='fw-bold fs-5 p-2'>
        Terms and Conditions
      </div>
      <div className='p-2' style={{ maxHeight: "100vh", overflowY: "scroll" }}>
        Welcome to LOGGINZ ("the App"). These terms and conditions ("Terms") outline the rules and regulations for using the App. By using the App, you accept these Terms in full. If you disagree with any part of these Terms, please do not use the App.<br /><br />

        1. Definitions<br />
        1.1 "App": Refers to LOGGINZ, its features, and associated services.<br />
        1.2 "User": Refers to any individual or entity using the App, including employees, managers, and HR personnel.<br /><br />

        2. Scope of Services<br />
        2.1 Description of Services:<br />
        ● The App provides HR management services, including but not limited to employee records, payroll processing, leave management, and performance tracking.<br />
        2.2 HR and Employee Interaction:<br />
        ● The App may facilitate communication between employees, HR representatives, and management for purposes such as leave requests, approvals, and other administrative processes.<br /><br />

        3. User Responsibilities<br />
        3.1 Accuracy of Information:<br />
        ● You are responsible for providing accurate and up-to-date information, including personal details, employment history, performance data, and leave records.<br />
        3.2 User Accounts:<br />
        ● You may be required to create an account to access certain features. You are responsible for maintaining the confidentiality of your account information.<br />
        3.3 Prohibited Activities:<br />
        ● You agree not to engage in any activities that may harm the App, other users, or violate applicable laws and regulations. This includes unauthorized access, data tampering, or misuse of information.<br />
        3.4 Disclaimer:<br />
        ● The App is a tool for managing HR tasks and is not responsible for providing legal or financial advice. For specific advice, consult your HR department or legal professionals.<br /><br />

        4. Privacy and Security<br />
        4.1 Privacy Policy:<br />
        ● Our Privacy Policy governs the collection, use, and protection of your information. Please review the Privacy Policy for more details.<br />
        4.2 Security Measures:<br />
        ● We employ industry-standard security measures to protect your personal and employment information. However, we cannot guarantee complete security. By using the App, you acknowledge and accept these risks.<br /><br />

        5. Intellectual Property<br />
        5.1 Ownership:<br />
        ● All content, trademarks, and intellectual property rights related to the App are owned by LOGGINZ.com<br />
        5.2 Use Restrictions:<br />
        ● You may not reproduce, distribute, or modify any part of the App without explicit permission from LOGGINZ.com.<br />
        5.3 User Content:<br />
        ● By submitting content (e.g., feedback, comments), you grant LOGGINZ.com a non-exclusive, worldwide, royalty-free license to use, reproduce, and distribute your content.<br /><br />

        6. Limitation of Liability<br />
        6.1 No Warranty:<br />
        ● The App is provided "as is" without any warranties, express or implied. We do not guarantee the accuracy, completeness, or suitability of the information provided.<br />
        6.2 Limitation of Liability:<br />
        ● LOGGINZ.com shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use or inability to use the App.<br /><br />

        7. Termination<br />
        7.1 Termination by LOGGINZ.com:<br />
        ● LOGGINZ.com may, at its discretion, suspend or terminate your access to the App for any reason, without notice.<br /><br />

        8. Governing Law<br />
        8.1 Jurisdiction:<br />
        ● These Terms shall be governed by and construed in accordance with the laws of the country where LOGGINZ.com is headquartered.<br /><br />

        9. Changes to Terms<br />
        9.1 Modification:<br />
        ● LOGGINZ.com reserves the right to update or modify these Terms at any time. Any changes will be effective immediately upon posting.<br /><br />

        10. Entire Agreement<br />
        10.1 Entire Agreement:<br />
        ● These Terms constitute the entire agreement between you and LOGGINZ.com regarding the use of the App.<br /><br />

        11. Severability<br />
        11.1 Severability:<br />
        ● If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.<br /><br />

        12. Location Data<br /> 
        12.1 Location Data:<br />
        ● When using our application, we may collect and process precise real-time location information from your device to generate attendance reports. This data is collected only when the app is in use or running in the background, as needed for attendance purposes.<br /><br />
        ● Attendance Tracking: To verify your presence at a specific location as part of our attendance reporting system.<br /><br />

        13. We take the security of your personal information seriously and implement measures such as encryption and secure storage of data. Only authorized personnel have access to this data, and it is used exclusively for attendance-related functions.<br /><br />

        14. Contact Information<br />
        If you have any questions about these Terms, please contact us at info@hrmz.com
      </div>
      <div className="border col-md-12 justify-content-end d-flex gap-2 p-3">
        <button className="btn btn-primary cancelbtn px-3 py-1" onClick={()=>navigate("/")}>
          {("Close")}
        </button>
      </div>
    </div>
  );
}

export default Privacy;
