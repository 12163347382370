export const approval = { approvalComments: { required: { value: true, message: "Please Write Comments" } } }
export const tasktype = { taskType: { required: { value: true, message: "Please Write Reason" } } }
export const addNote = { notes: { required: { value: true, message: "Please select Project type" } } }
export const technology = { technologyName: { required: { value: true, message: "Please select Project type" } } }
export const policies = { description: { required: { value: true, message: "Please enter Description" } }, acceptType: { required: { value: true, message: "Please enter Description" } } }
export const organization = { phone: { required: { value: true, message: "Please Enter phone" } } }

export const timesheet = {
  timeSheetTransaction: {
    isArray: { value: true, message: "Required" },
    innerSchema: { taskTypeId: { required: { value: true, message: "Please Enter itemCode" } } }
  }
}

export const skillSet = {
  technologyId: { required: { value: true, message: "Please Write Reason" } },
  level: { required: { value: true, message: "Please Write Reason" } }
}
export const request = {
  ticketName: { required: { value: true, message: "Please select Project type" } },
  departmentId: { required: { value: true, message: "Please select Project type" } }
}
export const addWorklocation = {
  locationName: { required: { value: true, message: "Please Write Reason" } },
  locationAddress: { required: { value: true, message: "Please select Project type" } },
  latitude: { required: { value: true, message: "Please enter Latitude" } },
  longitude: { required: { value: true, message: "Please enter Longitude" } }

}
export const concern = {
  toEmpId: { required: { value: true, message: "Please select Project type" } },
  description: { required: { value: true, message: "Please select Project type" } }
}
export const allreports = {
  fromDate: { required: { value: true, message: "Please Enter fromDate" } },
  toDate: { required: { value: true, message: "Please Enter toDate" } }
}
export const allLeavereports = {
  startDate: { required: { value: true, message: "Please Enter fromDate" } },
  endDate: { required: { value: true, message: "Please Enter toDate" } }
}
export const designation = {
  grade: { required: { value: true, message: "Please Enter phone" } },
  designationName: { required: { value: true, message: "Please Enter phone" } }
}
export const Addmeeting = {
  meetingName: { required: { value: true, message: "Please Enter phone" } },
  paricipateIds: { required: { value: true, message: "Please Enter phone" } }
}
export const workLocations = {
  workLocationId: { required: { value: true, message: "Please Enter locationPhone" } },
  toDate: { required: { value: true, message: "Please Enter To Date" } },
  fromDate: { required: { value: true, message: "Please Enter locationPhone" } }
}
export const projectUpload = {
  fileDescription: { required: { value: true, message: "Please Enter locationPhone" } },
  projectFiles: { required: { value: true, message: "Please Select a file" } }
}
export const sickLeaveUpload = {
  fileDesc: { required: { value: true, message: "Please Enter locationPhone" } },
  Image: { required: { value: true, message: "Please Select a file" } }
}
export const profileUpload = {
  fileType: { required: { value: true, message: "Please Enter file Type" } },
  uploadFile: { required: { value: true, message: "Please Select a file" } }
}


export const login = {
  email: {
    required: { value: true, message: "Please enter email", },
    pattern: { value: /^(?!.*[.]{2})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: "Please enter valid email" },
  },
  password: { required: { value: true, message: "Please enter password" } },
};

export const changePassword = {
  currentPassword: { required: { value: true, message: "Please Enter Current Password" } },
  newPassword: {
    required: { value: true, message: "Please Enter New Password" },
    pattern: {
      value: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_+=])[A-Za-z\d!@#$%^&*()-_+=]{8,}$/,
      message: " Password must be at least 8 characters long and contain at least one uppercase letter, one digit, and one special character."
    }
  }
}

export const forgetPassword = {
  password: {
    required: { value: true, message: "Please Enter New Password" },
    pettern: {
      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
      message: " Password should contain one uppercase, one lowercase, one special character, one number and should be equal to or greater than 8 characters"
    }
  },
  confirmPassword: { required: { value: true, message: "Please Enter Confirm Password" } }
}

export const employeevalidation = {
  personalEmail: {
    required: { value: true, message: "Please Enter Personal Email", },
    pattern: { value: /^(?!.*[.]{2})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: "Please enter valid email", },
  },
  officialEmail: {
    required: { value: true, message: "Please Enter Official Email" },
    pattern: { value: /^(?!.*[.]{2})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: "Please enter valid email" },
  },
};


export const timeSheetDetails = {
  hours: { required: { value: true, message: "Please Enter Task" } },
  minutes: { required: { value: true, message: "Please Enter Task" } },
  description: { required: { value: true, message: "Please Enter Task" } },
};
export const emergencyContactInfo = {
  contactName: { required: { value: true, message: "Please Write Reason" } },
  relation: { required: { value: true, message: "Please Write Reason" } },
  address: { required: { value: true, message: "Please Write Reason" } }
}

export const applyLeave = {
  leaveTypeId: { required: { value: true, message: "Please Select Leave Type" } },
  startDate: { required: { value: true, message: "Please Select From Date" } },
  endDate: { required: { value: true, message: "Please Select To Date" } },
  reasonForLeave: { required: { value: true, message: "Please Write Reason" } }
}
export const permission = {
  requestReason: { required: { value: true, message: "Please Write Reason" } },
  requestDate: { required: { value: true, message: "Please Write Reason" } },
  fromTime: { required: { value: true, message: "Please Write Reason" } },
  toTime: { required: { value: true, message: "Please Write Reason" } }
}
export const holiday = {
  worklocations: { required: { value: true, message: "Please select Project type" } },
  holidayName: { required: { value: true, message: "Please select Project type" } },
  holidayDate: { required: { value: true, message: "Please select Project type" } },
  holidayType: { required: { value: true, message: "Please select Project type" } }
}
export const announceMent = {
  description: { required: { value: true, message: "Please select Project type" } },
  title: { required: { value: true, message: "Please select Project type" } },
  announcementDate: { required: { value: true, message: "Please select Project type" } },
  expiryDate: { required: { value: true, message: "Please select Project type" } }
}
export const clients = {
  clientName: { required: { value: true, message: "Please Enter Client name" } },
 
}

export const assetManagement = {
  assettype: { required: { value: true, message: "Please Write Reason" } },
  modelName: { required: { value: true, message: "Please Write Reason" } },
  assetNumber: { required: { value: true, message: "Please Write Reason" } },
  allotNumber: { required: { value: true, message: "Please Write Reason" } },
  handoverDate: { required: { value: true, message: "Please Write Reason" } }
}
export const addProject = {
  projectName: { required: { value: true, message: "Please Write Reason" } },
  clientId: { required: { value: true, message: "Please Write Reason" } },
  projectStartDate: { required: { value: true, message: "Please Write Reason" } },
  managerId: { required: { value: true, message: "Please Write Reason" } },
  projectType: { required: { value: true, message: "Please select Project type" } },
  description: { required: { value: true, message: "Please enter description" } }
}
export const salarytrack = {
  currentSaraly: { required: { value: true, message: "Please Enter Current Salary" } },
  incrementPercentage: { required: { value: true, message: "Please Enter Increment Salary" } },
  effectiveFrom: { required: { value: true, message: "Please From Enter Date" } },
  effectiveTo: { required: { value: true, message: "Please Enter To Date" } },
  designationId: { required: { value: true, message: "Please Enter To Date" } }
}
export const salaryDeductions = {
  ctc: {
    required: { value: true, message: "Please Enter Current Salary" }
  }
}
export const referal = {
  name: { required: { value: true, message: "Please select Project type" } },
  email: {
    required: { value: true, message: "Please select Project type" },
    pattern: { value: /^(?!.*[.]{2})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: "Please enter valid email", }
  },
  mobile: { required: { value: true, message: "Please select Project type" } },
  skillSet: { required: { value: true, message: "Please select Project type" } },
  experience: { required: { value: true, message: "Please select Project type" } }
}

export const empBankdetails = {
  accHolderName: { required: { value: true, message: "Please Write Reason" } },
  accNum: { required: { value: true, message: "Please Write Reason" } },
  bankName: { required: { value: true, message: "Please Write Reason" } },
  branch: { required: { value: true, message: "Please Write Reason" } },
  branch: { required: { value: true, message: "Please Write Reason" } },
  ifsc: { required: { value: true, message: "Please Write Reason" } }
}

export const education = {
  school: { required: { value: true, message: "Please Enter School/College" } },
  board: { required: { value: true, message: "Please Enter Board/University" } },
  grade: { required: { value: true, message: "Please Enter Grade" } },
  startMonth: { required: { value: true, message: "Please Enter Start Date", } },
  startYear: { required: { value: true, message: "Please Enter Start Date" } },
  endtMonth: { required: { value: true, message: "Please Enter End Date" } },
  endYear: { required: { value: true, message: "Please Enter End Date" } },
};
export const workExperience = {
  jobTitle: { required: { value: true, message: "Please Write Reason" } },
  employmentType: { required: { value: true, message: "Please Write Reason" } },
  companyName: { required: { value: true, message: "Please Write Reason" } },
  startMonth: { required: { value: true, message: "Please Write Reason" } },
  startYear: { required: { value: true, message: "Please Write Reason" } },
  endMonth: { required: { value: true, message: "Please Write Reason" } },
  endYear: { required: { value: true, message: "Please Write Reason" } },
}

export const educationDetails = {
  school: { required: { value: true, message: "Please Write Reason" } },
  board: { required: { value: true, message: "Please Write Reason" } },
  degree: { required: { value: true, message: "Please Write Reason" } },
  grade: { required: { value: true, message: "Please Write Reason" } },
  startMonth: { required: { value: true, message: "Please Write Reason" } },
  startYear: { required: { value: true, message: "Please Write Reason" } },
  endMonth: { required: { value: true, message: "Please Write Reason" } },
  endYear: { required: { value: true, message: "Please Write Reason" } },
  
}

export const additionalInfo = {
  maritalStatus: { required: { value: true, message: "Please Write Reason" } },
  bloodGroup: { required: { value: true, message: "Please Write Reason" } },
  country: { required: { value: true, message: "Please Write Reason" } },
  state: { required: { value: true, message: "Please Write Reason" } },
  pincode: { required: { value: true, message: "Please Write Reason" } },
  empAddress: { required: { value: true, message: "Please Write Reason" } },
  pan: {
    required: { value: true, message: "Please Write Reason" },
    pattern: { value: /^[A-Z]{5}[0-9]{4}[A-Z]$/, message: "Enter valid Pan" }
  },
  aadhar: {
    required: { value: true, message: "Please Write Reason" },
    minlength: { value: 14, message: "Please enter 3 characters" }
  }
}

export const addEmployee = {
  jobCategory: { required: { value: true, message: "Please Write Reason" } },
  firstName: { required: { value: true, message: "Please Write Reason" } },
  lastName: { required: { value: true, message: "Please Write Reason" } },
  fatherName: { required: { value: true, message: "Please Write Reason" } },
  gender: { required: { value: true, message: "Please Write Reason" } },
  contactNo: {
    required: { value: true, message: "Please Write Reason" },
    minlength: { value: 15, message: "Please enter valid mobile" }
  },
  personalEmail: {
    required: { value: true, message: "Please Write Reason" },
    pattern: { value: /^(?!.*[.]{2})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: "Please enter valid email", },
  },
  dobCertificate: { required: { value: true, message: "Please Write Reason" } },
  dobOriginal: { required: { value: true, message: "Please Write Reason" } },
  roleId: { required: { value: true, message: "Please Write Reason" } },
  workType: { required: { value: true, message: "Please Write Reason" } },
  workLocationId: { required: { value: true, message: "Please Write Reason" } },
  departmentId: { required: { value: true, message: "Please Write Reason" } },
  designationId: { required: { value: true, message: "Please Write Reason" } },
  reportingManagerId: { required: { value: true, message: "Please Write Reason" } },
  dateOfJoining: { required: { value: true, message: "Please Write Reason" } },
  pf: { required: { value: true, message: "Please Write Reason" } },
  timeZoneId: { required: { value: true, message: "Please Write Reason" } },
}

export const supplyRequest = {
  supplyRequestEntities: {
    isArray: { value: true, message: "Required" },
    innerSchema: {
      school: { required: { value: true, message: "Please Enter itemCode" } },
      board: { required: { value: true, message: "Please Enter quantity" } }
    }
  }
}

export const assignEmployesCopy = {
  TeamMembers: {
    isArray: { value: true, message: "Required" },
    required: { value: true, message: "Required" },
    minlength: { value: 1, message: "Please give atleast 1" },
    innerSchema: {
      empId: {
        required: { value: true, message: "Please Enter itemCode" },
        minlength: { value: 1, message: "Please select minimum one" }
      },
      technologyIds: {
        required: { value: true, message: "Please Enter quantity" },
        minlength: { value: 1, message: "Please select minimum one" }
      },
    }
  },
}

export const assignEmployes = {
  empId: { required: { value: true, message: "Please Enter itemCode" } },
  technologyIds: {
    required: { value: true, message: "Please Enter quantity" },
    minlength: { value: 1, message: "Please select minimum one" }
  },
}