import axios from "axios";
import instance from "../Services/Index.js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseURL } from "./Consts.js";

export const notify = (status, msg) => {
  toast.configure();
  if (status == true) {
    toast.success(msg);
    return <ToastContainer />;
  } else {
    toast.error(msg);
    return <ToastContainer />;
  }
};

const sessionExpired = (istokenExpired) => {
  if (istokenExpired == true) {
    window.location.pathname = '/sessionexpired'
  }
}

export const getList = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    sessionExpired(res?.data?.istokenExpired)
    if (res?.data?.status == true && res?.data?.data?.length != 0) {
      if (res.data.data?.length == undefined) {
        return [];
      } else {
        return res.data.data;
      }
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
};

export const getfList = async (url) => {
  try {
    let res = await instance.get(url);
    sessionExpired(res?.data?.istokenExpired)
    if (res?.data?.status == true && res?.data?.data?.length != 0) {
      if (res.data.data?.length == undefined) {
        return [];
      } else {
        return res.data.data;
      }
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
};

export const save = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    sessionExpired(res?.data?.istokenExpired)
    notify(res?.data?.status, res?.data?.message);
    if (res?.data?.status == true) {
      return res;
    } else {
      return {};
    }
  } catch (e) {
    return {};
  }
};

export const saveWithoutMessage = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    sessionExpired(res?.data?.istokenExpired)
    // notify(res?.data?.status, res?.data?.message);
    if (res?.data?.status == true) {
      return res;
    } else {
      return {};
    }
  } catch (e) {
    return {};
  }
};

export const add = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    sessionExpired(res?.data?.istokenExpired)
    notify(res?.data?.status, res?.data?.message);
    if (res?.data?.status == true) {
      return res;
    } else {
      return {};
    }
  } catch (e) {
    return {};
  }
};

export const getById = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    sessionExpired(res?.data?.istokenExpired)
    if (res?.data?.status == true && Object.keys(res?.data?.data)?.length != 0) {
      return res.data.data;
    } else {
      return {};
    }
  } catch (e) {
    return {};
  }
};
export const checkedFn = (data, value) => {
  return data && data == value ? true : false;
};


export const saveWithoutMessageWithoutLoad = async (url, data) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + sessionStorage.getItem("token"),
  };
  try {
    let res = await axios?.post(`${baseURL}${url}`, data, { headers });
    sessionExpired(res?.data?.istokenExpired)
    if (res?.data?.status == true) {
      return res;
    } else {
      return {};
    }
  } catch (e) {
    return {};
  }
};