import React, { useEffect, useState } from "react";
import { getList, save } from "../../Config/SharedFunctions";
import { basefileURL, urls } from "../../Config/Consts";
import { decryptData, getUserfromSS, logOut } from "../../Config/SmallFunctions";
import { Button, Form, ListGroup, Modal } from "react-bootstrap";
import { BsCloudDownload } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";

const PolicyAcceptency = () => {
  const { id } = useParams();
  const [policies, setPolicies] = useState([]);
  const [noinfo, setNoInfo] = useState(false);
  const [showPopup, setShowPopup] = useState(true); // Show popup by default
  const [update, setUpdate] = useState(Date.now());
  const navigate = useNavigate();

  const getEmployeePolicies = async () => {
    let res = await getList(urls?.Policy?.GetEmployeePolicy, { id: id });
    setPolicies(res);
  };

  useEffect(() => {
    getEmployeePolicies();
  }, [update]);

  const SaveAcceptPolicies = async (policyId, status) => {
    let body = { policyId: policyId, empId: id, isAccepted: status };
    let res = await save(urls?.Policy?.SaveEmployeePolicy, body);
    if (res?.data?.status === true) setUpdate(Date.now());
  };

  useEffect(() => {
    let noData = policies?.filter((a) => a?.acceptType === "Mandatory")?.every((e) => e?.isAccepted === true);
    setNoInfo(noData);
  }, [policies]);
  // useEffect(()=>{console.log(id,"uid")},[id])

  return (
    <>
      <Modal size="xl" show={showPopup} onHide={() => setShowPopup(false)} centered  backdrop="static">
        <Modal.Header>
          <Modal.Title className="d-flex align-items-center">
            <span className="ms-1">Policy Terms and Conditions</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup className="d-flex flex-column gap-1" style={{ width: "auto", height: "40vh", overflow: "auto" }}>
            {policies?.length > 0 ? (
              <>
                {!noinfo ? (
                  policies
                    ?.filter((a) => a?.acceptType === "Mandatory" && a?.isAccepted !== true)
                    ?.map((e) => (
                      <div key={e.policyId} className="d-flex flex-column p-1 rounded border justify-content-center align-items-center pointer" title="Click to wish" style={{ background: "#CEE8CA", maxHeight: "18vh" }}>
                        <div className="col-md-12">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="fw-bold text-start col-10 ps-xl-2" style={{ fontSize: "1vw" }}>{`${e.description}`}</div>
                            <div className="col-1">
                              <span>
                                {e?.fileName?.length > 0 && (
                                  <a href={`${basefileURL}Policy/${e?.fileName}`} target={"_blank"} className="mb-1 ms-3" download>
                                    <i>
                                      <BsCloudDownload size={20} title="Download Policy" className="text-primary pointer" />
                                    </i>
                                  </a>
                                )}
                              </span>
                            </div>
                            <div className="col-1">
                              <Button size="sm" onClick={() => SaveAcceptPolicies(e?.policyId, true)}>
                                Accept
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                ) : (
                  <div className="d-flex justify-content-center align-items-center   h-100">
                    <div className="text-center">
                      <div>You are accepted all Policy terms and conditions</div>
                      <div>
                        Please Click on{" "}
                        <span>
                          <div className="btn btn-link" onClick={() => navigate("/")}>
                            Login
                          </div>
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <Form.Label className="text-danger">No records found</Form.Label>
            )}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => navigate("/")}>
            Log In
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PolicyAcceptency;
