import { useState, useEffect } from "react";
import logo1 from "../../Assets/logo2.png";
import { InputGroup, Form, Container, Row, Col, Card, Button } from "react-bootstrap";
import { GoEye, GoEyeClosed } from "react-icons/go";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UseFormValidations } from "../../FormValidation/UseFormValidations";
import "./Auth.css";
import { changePassword, forgetPassword, login, updatePassword } from "../../Config/ValidationSchema";
import { notify, save } from "../../Config/SharedFunctions";
import { urls } from "../../Config/Consts";
import { returnErrorColor } from "../../Config/SmallFunctions";
const ChangePassword = ({ close }) => {
  const navigate = useNavigate();
  const [type1, setType1] = useState("password");
  const [type2, setType2] = useState("password");
  const isPasswordComplex = (password) => {
    const capitalLetterRegex = /[A-Z]/;
    const smallLetterRegex = /[a-z]/;
    const specialCharacterRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
    const numberRegex = /[0-9]/;
    return (
      password.length >= 6 &&
      capitalLetterRegex.test(password) &&
      smallLetterRegex.test(password) &&
      specialCharacterRegex.test(password) &&
      numberRegex.test(password)
    );
  };
  // const submit = async () => {
  //   if (data?.currentPassword != data?.newPassword) {
  //     if (!isPasswordComplex(data?.newPassword)) {
  //       notify(false, " New password must contain at least one capital letter, one small letter, one special character, one number, and have a length greater than 6 characters.");
  //       return;
  //     }
  //     let res = await save(urls.Auth.UpdatePassword, data);
  //     if (res?.data?.status == true) {
  //       close()
  //     }
  //   } else {
  //     notify(false, "Current Password and New Password should not be Same");
  //   }
  // };
  const submit = async () => {
    if (data?.currentPassword != data?.newPassword) {
      let res = await save(urls.Auth.UpdatePassword, data);
      if (res?.data?.status == true) {
        close()
      }
    } else notify(false, "Current Password and New Password should not be Same");
  };
  const { data, errors, handleSubmit, formChange } = UseFormValidations({
    validationSchema: changePassword,
    submit: submit,
  });
  return (
    <div className="">


      <div></div>
      <Col lg={12} xs={12} md={6} className="d-flex justify-content-center">
        <Card className="  px-sm-3 px-md-4 px-lg-5 py-lg-3 my-lg-5 my-md-2 mt-1" style={{ backgroundColor: "" }}>
          <Card.Body>
            <div className="text-center mb-xl-1 mb-lg-1">
              <img src={logo1} height={150} />
            </div>

            <div id="login-form">
              <Form.Group className="mb-3">
                <Form.Label>Current Password<span className="text-danger">*</span></Form.Label>
                <InputGroup hasValidation>
                  <Form.Control size="lg" type={type1} name="currentPassword" required placeholder="Current Password" className={`input-heigt password rounded-0 ${returnErrorColor(errors?.currentPassword)}`} onChange={formChange("")} />
                  <InputGroup.Text className={` rounded-0 password eyeiconborder ${returnErrorColor(errors?.currentPassword)}`}  >
                    <span onClick={() => setType1(type1 == "password" ? "text" : "password")} id="togglePassword">
                      {type1 == "password" ? <GoEyeClosed /> : <GoEye />}
                    </span>
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>New Password<span className="text-danger">*</span></Form.Label>
                <InputGroup hasValidation>
                  <Form.Control size="lg" type={type2} name="newPassword" required placeholder="New Password" className={`input-heigt password rounded-0 ${returnErrorColor(errors?.newPassword)}`} onChange={formChange("")} />
                  <InputGroup.Text className={`input-heigt password rounded-0 ${returnErrorColor(errors?.newPassword)}`}>
                    <span onClick={() => setType2(type2 == "password" ? "text" : "password")} id="togglePassword">
                      {type2 == "password" ? <GoEyeClosed /> : <GoEye />}
                    </span>
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>

              <div className="text-center">
                <Button onClick={handleSubmit} size="lg"  >
                  Save
                </Button>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>


    </div>

  );
};
export default ChangePassword;