export const baseURL = process.env.REACT_APP_API_URL;
export const basefileURL = process.env.REACT_APP_API_ASSETS_URL;
export const chatUrl = process.env.REACT_APP_CHAT_URL;
export const jitsiMeetUrl = "https://video.tribandtech.com:8443/logginz/"
export const css = {
  all: "#935A91",
  approved: "#1EC198",
  pending: "#FFBF65",
  reject: "#D66B8C",
  cancel: "#FF8C78",
};
export const urls = {
  Auth: {
    login: "Authentication/Login",
    SendForgotPasswordMail: "Authentication/SendForgotPasswordMail",
    UpdatePassword: "Authentication/UpdatePassword",
    CreatePassword: "Authentication/SetPassword",
  },
  chat: {
    getAll: "Chat/GetChat",
    save: "Chat/Save",
    read: "Chat/ReadMessages",
  },
  Organization: {
    Save: "Organization/Save",
    GetAll: "Organization/GetAll",
    GetById: "Organization/GetById",
    SaveOrganizationHead: "Organization/SaveOrganizationHead",
    GetOrganizationHeadById: "Organization/GetOrganizationHeadById",
    GetAllOrganizationHead: "Organization/GetAllOrganizationHead",
  },

  Employee: {
    Save: "Employee/Save",
    GetById: "Employee/GetById",
    GetAll: "Employee/GetAll",
    GetReportingManagers: "Employee/GetReportingManagers",
    SaveEducation: "Employee/SaveEmployeeEducation",
    GetManagers: "Employee/GetManagers",
    SaveUploads: "Employee/SaveUploads",
    FinalSubmit: "Employee/EmployeeFinalSubmit",
    SaveBank: "Employee/SaveEmployeeBank",
    UpdateStatus: "Employee/UpdateStatus",
    SendMail: "Employee/SendMailToEmployee",
    SaveAdditionalInfo: "Employee/SaveAdditionalInfo",
    AddProfilePhoto: "Employee/AddProfilePhoto",
    GetProfilePhoto: "Employee/GetProfilePhoto",
    GetAllTimeZone: "Employee/GetAllTimeZone",
    SaveEmployeeWorkLocation: "Employee/SaveEmployeeWorkLocation",
    GetEmpWorkLocationByEmpId: "Employee/GetEmpWorkLocationByEmpId",
    GetEmployeeWorkLocationById: "Employee/GetEmployeeWorkLocationById",
    GenerateEmployeeId: "Employee/GenerateEmployeeId",
    AddPhotoGallery: "Employee/AddPhotoGallery",
    GetAllPhotoGallery: "Employee/GetAllPhotoGallery",
    DeletePhotoGallery: "Employee/DeletePhotoGallery",
    DeleteFileUploads: "Employee/DeleteFileUploads",
    ImportFromFiles: "Employee/ImportFromFiles",
    DashBoard: "Employee/DashBoard",
    SaveSalary: "Employee/AdditionalDeduction/Save",
    AdditionalDeductionGetById: "Employee/AdditionalDeduction/GetById",
    StatusUpdate: "Employee/StatusUpdate",
    GetAllEmployeeLookup: "Employee/GetAllEmployeeLookup",
    GetAllEmployeeAttendance: "Employee/GetAllEmployeeAttendance",
    GetByIdemployeeattendance: "Employee/GetByIdemployeeattendance",
    SaveEmployeeAttendance: "Employee/SaveEmployeeAttendance",
    GetemployeeattendanceByDate: "Employee/GetemployeeattendanceByDate",
    GetAllEmployeeAttendances: "Employee/GetAllEmployeeAttendances",
    AttendanceCount: "Employee/AttendanceCount",
    GetEmployeeProfileStatus: "Employee/GetEmployeeProfileStatus",
    ModifyAuthenticationRole: "Authentication/ModifyAuthenticationRole",
    FeatchemployeeattendanceByDate: "Employee/Featchemployeeattendance"
  },
  MonthlySalary: {
    GetAllMonthlySalary: "MonthlySalary/GetAllMonthlySalary",
    UpdateEmpMonthlySalary: "MonthlySalary/UpdateEmpMonthlySalary",
    UpdateEmpSalaryProcess: "MonthlySalary/UpdateEmpSalaryProcess",
  },

  ISOCategory: {
    Save: "ISOCategory/Save",
    GetAll: "ISOCategory/GetAll",
    GetById: "ISOCategory/GetById",
  },
  ISOForm: {
    Save: "ISOForm/Save",
    GetAll: "ISOForm/GetAll",
    GetById: "ISOForm/GetById",
  },
  FormDetails: {
    Save: "FormDetails/Save",
    GetAll: "FormDetails/GetAll",
    GetById: "FormDetails/GetById",
  },

  Project: {
    Save: "Project/Save",
    GetById: "Project/GetById",
    GetAssignedProjectsForEmployee: "Project/GetAssignedProjectsForEmployee",
    AssignManager: "Project/AssignManager",
    AddTeamMembers: "Project/AddTeamMembers",
    AddTeamMember: "Project/AddTeamMember",
    GetTeamMembers: "Project/GetTeamMembers",
    SaveProjectFiles: "Project/SaveProjectFiles",
    GetProjectFiles: "Project/GetProjectFiles",
    DeleteProjectFiles: "Project/DeleteProjectFiles",
    SaveTechnicalProjectFiles: "Project/SaveTechnicalProjectFiles",
    GetTechnicalProjectFiles: "Project/GetTechnicalProjectFiles",
    UpdateStatus: "Project/UpdateProjectStatus",
    GetAllProjectStatus: "Project/GetAllProjectStatus",
    GetClientProjectDetails: "Project/GetProjectDetails",
    DeleteTechnicalProjectFiles: "Project/DeleteTechnicalProjectFiles",
    AssignEmployee: "Project/UpdateTeamMemberStatus",
    GetTeamMemberStatusList: "Project/GetTeamMemberStatus",
  },

  Department: {
    Save: "Department/Save",
    GetAll: "Department/GetAll",
    GetById: "Department/GetById",
  },
  Designation: {
    Save: "Designation/Save",
    GetAll: "Designation/GetAll",
    GetById: "Designation/GetById",
  },
  Ticket: {
    Save: "Ticket/Save",
    GetAll: "Ticket/GetAll",
    GetById: "Ticket/GetById",
    UpdateStatus: "Ticket/UpdateStatus",
    SaveNote: "Ticket/SaveNotes",
    GetAllNotes: "Ticket/GetAllNotes",
  },
  Role: {
    GetAll: "Role/GetAll",
    GetById: "Role/GetById",
    Save: "Role/Save",
  },
  Technology: {
    Save: "Technology/Save",
    GetAll: "Technology/GetAll",
    GetById: "Technology/GetById",
  },
  Meeting: {
    Save: "Meeting/Save",
    GetAll: "Meeting/GetAll",
    SaveParticipants: "Meeting/SaveParticipants",
    CancelMeeting: "Meeting/CancelMeetings",
  },
  TimeSheet: {
    Save: "TimeSheet/Save",
    GetAll: "TimeSheet/GetAll",
    GetAllTimeSheetTran: "TimeSheet/GetAllTimeSheetTran",
    GetManageTimesheetList: "TimeSheet/GetManageTimesheetList ",
    GetManageMonthlyTimesheetList: "TimeSheet/GetManageMonthlyTimesheetList",
    GetById: "TimeSheet/GetById",
    UpdateStatus: "Timesheet/UpdateStatus",
    UpdateFinalSubmit: "Timesheet/UpdateFinalSubmit",
    GetTimesheetDataForExcel: "TimeSheet/GetTimesheetDataForExcel",
    GetMyTimesheet: "TimeSheet/GetMyTimesheet",
    SendTimesheetReminderMail: "Timesheet/SendTimesheetReminderMail",
    GetTimesheetTranById: "Timesheet/GetTimesheetTranById",
    GetEmpListForManager: "Timesheet/GetEmpListForManager",
  },
  LeaveType: {
    GetAll: "LeaveType/GetAll",
  },
  Leaves: {
    Save: "Leaves/Save",
    GetEmployeeBalanceLeaves: "Leaves/GetEmployeeBalanceLeaves",
    // GetAll: "Leaves/GetAll", /////
    GetById: "Leaves/GetById",
    // GetLeavesForManager: "Leaves/GetLeavesForManager", //// 
    UpdateStatus: "Leaves/UpdateStatus",
    UpdateBulk: "Leaves/BulkUpdateStatus",
    // GetLeaveSummary: "Leaves/GetLeaveSummary",  ///
    // GetLeavesForAdmin: "Leaves/GetLeavesForAdmin", ///
    AddSickLeaveDoc: "Leaves/AddSickLeaveDoc",
    GetSickLeaveDoc: "Leaves/GetSickLeaveDoc",
    DeleteSickLeaveDoc: "Leaves/DeleteSickLeaveDoc",
    GetLeavesData: "Leaves/GetLeavesData",
    GetLeaveBalanceEachMonth: "Leaves/GetEmployeeBalanceLeavesReport",
    GetAllNew: "Leaves/GetAllLeaves"
  },

  LeaveBalance: {
    getAll: "LeaveBalance/getAll",
    Save: "LeaveBalance/Save",
    AssignUnAssignBulkLeaves: "LeaveBalance/AssignUnAssignBulkLeaves",
  },
  Holidays: {
    GetAll: "Holidays/GetAll",
    Save: "Holidays/Save",
    GetById: "Holidays/GetById",
    DeleteHolidayById: "Holidays/DeleteHolidayById",
    HolidaySave: "Holidays/HolidaySave"
  },
  Announcement: {
    Save: "Announcement/Save",
    GetAll: "Announcement/GetAll",
    DeleteAnnouncementById: "Announcement/DeleteAnnouncementById",
    GetById: "Announcement/GetById",
  },
  Client: {
    Save: "Client/Save",
    GetAll: "Client/GetAll",
    GetById: "Client/GetById",
    UpdateStatus: "Client/UpdateStatus",
    UploadProfile: "Client/AddClientProfilePhoto",
  },
  Designation: {
    Save: "Designation/Save",
    GetAll: "Designation/GetAll",
    GetById: "Designation/GetById",
    ImportFromFile: "Designation/ImportFromFile",
  },
  WorkLocation: {
    GetAll: "WorkLocation/GetAll",
    GetById: "WorkLocation/GetById",
    GetEmpWorkLocationById: "WorkLocation/GetEmpWorkLocationById",
    Save: "WorkLocation/Save",
  },

  HRPermission: {
    Save: "HRPermission/Save",
    GetById: "HRPermission/GetById",
    // GetAll: "HRPermission/GetAll",  ///
    UpdateStatus: "HRPermission/UpdateStatus",
    UpdateBulk: "HRPermission/BulkUpdateStatus",
    // GetPermissionForManager: "HRPermission/GetPermissionForManager", ///
    // GetPermissionForAdmin: "HRPermission/GetPermissionForAdmin",  ////
    GetAllNew: "HRPermission/GetAllPermissions"
  },
  userAccess: {
    getbyId: "UserAccess/GetRecursiveAccessLinks",
    save: "UserAccess/UpdateAccessLinkStatus",
    getUserAccessLinks: "UserAccess/SubscriptionUserAccessLinks",
  },
  Salary: {
    SaveSalaryTrack: "Salary/SaveSalaryTrack",
    GetAllSalaryTrack: "Salary/GetAllSalaryTrack",
    GetByIdSalaryTrack: "Salary/GetByIdSalaryTrack",
    SingleDeductions: "Employee/AdditionalDeduction/SaveSingle",
    GetAllDeductions: "Employee/AdditionalDeduction/GetAll",
    GetByIdDeduction: "Employee/GetByIdDeduction",
  },
  SalaryModule: {
    SaveSalaryStructure: "SalaryModule/SaveSalaryStructure",
    GetSalaryStructure: "SalaryModule/GetSalaryStructure",
    SaveEmployeeTaxExcemptions: "SalaryModule/SaveEmployeeTaxExcemptions",
    GetAllEmpTaxExcemptions: "SalaryModule/GetAllEmpTaxExcemptions",
    GetAllSalaryComponentConfig: "SalaryModule/GetAllSalaryComponentConfig",
    ProcessMonthlySalary: "SalaryModule/ProcessMonthlySalary",
    GetMonthlySalary: "SalaryModule/GetMonthlySalary",
    UpdateStatus: "SalaryModule/UpdateStatus",
    BulkUpdateStatus: "SalaryModule/BulkUpdateStatus",
    UpdateEmployeeMonthlySalary: "SalaryModule/UpdateEmployeeMonthlySalary",
    GetAllMonthlySalary: "SalaryModule/GetAllMonthlySalary",
    MonthlySalaryGetbyId: "SalaryModule/MonthlySalaryGetById",
  },

  Assets: {
    GetById: "Assets/GetById",
    GetAll: "Assets/GetAll",
    Save: "Assets/Save",
  },
  TaskType: {
    Save: "TaskType/Save",
    GetById: "TaskType/GetById",
    GetAll: "TaskType/GetAll",
  },
  EmployeeSkillSet: {
    Save: "EmployeeSkillSet/Save",
    GetAll: "EmployeeSkillSet/GetAll",
    UpdateStatus: "EmployeeSkillSet/UpdateStatus",
  },
  Concern: {
    Save: "Concern/Save",
    GetAll: "Concern/GetAll",
    GetById: "Concern/GetById",
  },
  EmployeeReferral: {
    Save: "EmployeeReferral/Save",
    UploadResume: "EmployeeReferral/UploadResume",
    GetAll: "EmployeeReferral/GetAll",
    GetById: "EmployeeReferral/GetById",
  },
  Policy: {
    Save: "Policy/Save",
    UploadPolicy: "Policy/UploadPolicy",
    Uploads: "Policy/Uploads",
    GetAll: "Policy/GetAll",
    GetById: "Policy/GetById",
    SaveEmployeePolicy: "Policy/SaveEmployeePolicy",
    GetEmployeePolicy: "Policy/GetEmployeePolicy",
  },
  Home: {
    GetAllCountry: "Home/GetAllCountry",
    NotificationInfo: "Home/NotificationInfo",
  },
};
